@import 'variables';

@import '../node_modules/bootstrap/scss/bootstrap';

// @import '../node_modules/font-awesome/scss/font-awesome';

@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700|Source+Sans+Pro:400,400i,600,700');

body {
  background-color: #FFFFFF;
}

.authentication {
  padding-top: 50px !important;
}

label {
  font-size: 0.8em;
  font-weight: bold;
  color: black;
}

.sign-in {
  .icon, h3 {
    display: none;
  }
  .card-body {
    margin-top: 0;
  }
}
